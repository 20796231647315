exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-explore-tsx": () => import("./../../../src/pages/explore/[...].tsx" /* webpackChunkName: "component---src-pages-explore-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lab-index-tsx": () => import("./../../../src/pages/lab/index.tsx" /* webpackChunkName: "component---src-pages-lab-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/Article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-lab-index-tsx": () => import("./../../../src/templates/Lab/index.tsx" /* webpackChunkName: "component---src-templates-lab-index-tsx" */),
  "component---src-templates-tutorial-index-tsx": () => import("./../../../src/templates/Tutorial/index.tsx" /* webpackChunkName: "component---src-templates-tutorial-index-tsx" */)
}

